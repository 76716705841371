import { Page } from "@bagel-web/components";
import Link from "next/link";

function AdminIndex() {
  return (
    <Page>
      <Page.Body>
        <h2>Tools and documentation</h2>

        <ul>
          <li>
            <Link href="/organizations">Organizations</Link>
            <br />
            Manage organizations
          </li>

          <li>
            <Link href="/sessions">Sessions</Link>
            <br />
            Search recent sessions
          </li>

          <li>
            <Link href="/chalk-logs">Chalk logs</Link>
            <br />
            Search recent chalk logs
          </li>

          <li>
            <Link href="/api-requests">API requests</Link>
            <br />
            View recent API requests
          </li>

          <li>
            <Link href="/feature-flags">Feature flags</Link>
            <br />
            View / edit feature flags
          </li>

          <li>
            <Link href="/documentation">Documentation</Link>
            <br />
            API and supported models
          </li>
        </ul>
      </Page.Body>
    </Page>
  );
}

export default AdminIndex;
